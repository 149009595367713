"use strict";
/**
 * Sticky UTM and Ad parameters.
 */
const persistUtmParameters = () => {
    const PARAMETERS = [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_term",
        "utm_content",
        "gclid",
        "fbclid",
        "msclid",
    ];
    const MAX_AGE = 1209600; /* two weeks */
    const utmCookieExists = !!document.cookie
        .split("; ")
        .find((c) => c.startsWith("UTM="))
        ?.split("=")[1];
    if (utmCookieExists) {
        return;
    }
    const determineReferer = (url) => {
        if (url.length < 1)
            return;
        const referrerUrl = new URL(url);
        return referrerUrl.hostname.split(".").filter((frag) => frag !== "www")[0];
    };
    const referer = determineReferer(document.referrer);
    const query = new URLSearchParams(document.location.search);
    const utmParams = new URLSearchParams();
    if (referer) {
        utmParams.set("referral_url", referer);
    }
    for (const name of PARAMETERS) {
        const value = query.get(name);
        if (value !== null) {
            utmParams.set(name, value);
        }
    }
    const cookieValue = btoa(utmParams.toString());
    if (cookieValue.length > 0) {
        document.cookie = `UTM=${cookieValue}; Domain=.rula.com; Path=/; Max-Age=${MAX_AGE}`;
    }
};
persistUtmParameters();
